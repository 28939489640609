<template>
  <transition-group name="custom-classes-transition"
    enter-active-class="animated slideInLeft"
    leave-active-class="animated slideOutLeft">
    <div v-show="toggleSideMenu"
      :style="{ 'min-width': inlineStyle }"
      class="tile is-parent"
      key="tile-parent">
      <article key="tile-child"
        class="tile is-child box detail-page-tile"
        :style="{ 'min-width': inlineStyle }">
        <slot class="side-menu"
          name="side-menu"
          :style="{ 'min-width': inlineStyle }" />
      </article>
    </div>
  </transition-group>
</template>

<script>
import { EventHubTypes } from '@/enums'

export default {
  name: 'AppSideMenu',
  props: {
    minWidth: {
      type: Number,
      default: 18
    },
    visibleWidth: {
      type: Number,
      default: 1008
    },
    fixedMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inlineStyle: '',
      windowWidth: 0,
      windowHeigth: 0,
      toggleSideMenu: true,
      isTouchDevice: false
    }
  },
  watch: {
    minWidth: function (newVal, oldVal) {
      this.inlineStyle = this.minWidth.toString() + 'vw'
    }
  },
  created() {
    this.$eventHub.$on(EventHubTypes.ToggleSideMenu, (data) => {
      this.handleSideMenuEvent(data)
    })
  },
  mounted() {
    this.inlineStyle = this.minWidth.toString() + 'vw'
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      window.addEventListener('resize', this.getWindowHeight)

      this.getWindowWidth()
      this.getWindowHeight()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowWidth(event) {
      this.windowWidth = window.innerWidth // document.documentElement.clientWidth
      if (this.toggleSideMenu && this.windowWidth <= this.visibleWidth) {
        this.toggleSideMenu = false
        this.isTouchDevice = true
        this.$eventHub.$emit(EventHubTypes.AutoHideSideMenu, true)
      } else if (!this.toggleSideMenu && this.windowWidth > this.visibleWidth) {
        this.toggleSideMenu = true
        this.isTouchDevice = false
        this.$eventHub.$emit(EventHubTypes.AutoHideSideMenu, false)
      }
    },
    getWindowHeight(event) {
      this.windowHeight = window.innerHeight // document.documentElement.clientHeight
    },
    handleSideMenuEvent(data) {
      this.toggleSideMenu = data || (this.fixedMenu && !this.isTouchDevice)
    }
  }
}
</script>

<style lang="scss" scoped>
$animation-time: 0.5s;

.side-menu {
  min-height: 100%;
  max-width: 40vw;
}

.slideInLeft {
  -webkit-animation: slideInLeft $animation-time;
  /* Safari 4+ */
  -moz-animation: slideInLeft $animation-time;
  /* Fx 5+ */
  -o-animation: slideInLeft $animation-time;
  /* Opera 12+ */
  animation: slideInLeft $animation-time;
  /* IE 10+, Fx 29+ */
}

.slideOutLeft {
  -webkit-animation: slideOutLeft $animation-time;
  /* Safari 4+ */
  -moz-animation: slideOutLeft $animation-time;
  /* Fx 5+ */
  -o-animation: slideOutLeft $animation-time;
  /* Opera 12+ */
  animation: slideOutLeft $animation-time;
  /* IE 10+, Fx 29+ */
}
</style>
